import { useParams, useNavigate } from "react-router-dom";

import ModuleBreadCrumb from "../../../components/breadCrumb";
import ModuleShipForm from "./form";

export default function BackShipDetail() {
  const route_params = useParams();
  const route_navigate = useNavigate();
  const crumbItem = [
    {
      key: 1,
      label: "出貨管理",
      command: () => route_navigate("/back/ship"),
    },
    { key: 2, label: "編輯" },
  ];

  return (
    <>
      <ModuleBreadCrumb crumbItem={crumbItem} />

      <div className="border-round-lg bg-white mt-3 p-3">
        <h2 className="mt-2 mb-4 ml-2">出貨管理 - 編輯</h2>

        <ModuleShipForm moduleType="back" detailid={route_params.detailid} />
      </div>
    </>
  );
}
