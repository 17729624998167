import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import {
  useAbortedEffect,
  useTable,
  usePop,
} from "../../../../components/hooks";
import { MachineAPI } from "../../../../axios/api";
import moment from "moment";

import ModulePopMachineDailyShipForm from "./popShipForm";
import { decimalAddZero, setPageSetting } from "../../../../service/common";

export default function ModuleMachineDailyShipList({
  preReload,
  setPreReload,
  search,
  setFilterData,
  setSearch,
  pagePath,
  optionData,
  dateInterval,
}) {
  const dispatch = useDispatch();
  const {
    tbData,
    setTbData,
    setRawTbData,
    setTbColumns,
    renderTable,
    setPageAction,
  } = useTable();
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "ship",
      title: () => "出貨",
      isOpen: false,
    },
  ]);

  useEffect(() => {
    const action = ({ startIndex, rows }) => {
      let temp = {
        ...search,
        page: Math.floor(startIndex / rows) + 1,
        size: rows,
      };
      setFilterData(temp);
      setSearch(temp);
    };
    setPageAction(
      () =>
        ({ startIndex, rows }) =>
          action({ startIndex, rows })
    );
  }, [search]);

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!preReload.list || !optionData) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
        date: moment(search.date).startOf("month").format("YYYY-MM-DD"),
        days: Number(moment(search.date).endOf("month").format("DD")),
      };

      MachineAPI.getDailyProduceRecordList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, message } = result;
        if (Response === 1) {
          let shipData = Object.entries(data.ship_records).reduce(
            (preModel, [key, val]) => ({
              ...preModel,
              [key]: val.reduce(
                (preDate, curDate) => ({
                  ...preDate,
                  [moment(curDate.date).format("MM/DD")]: {
                    ...curDate,
                    numbers_format: decimalAddZero({
                      rawVal: curDate.numbers ?? 0,
                      decimalShowRaw: true,
                    }),
                  },
                }),
                {}
              ),
            }),
            {}
          );

          // 沒有日程資料但有出貨紀錄
          let shipKey = Object.keys(data.ship_records);
          shipKey.forEach((key) => {
            if (!data.daily_produce_record[key])
              data.daily_produce_record[key] = [];
          });

          data = Object.entries(data.daily_produce_record).map(
            ([modelKey, val]) => {
              let produce_amount = val.reduce(
                  (pre, cur) => (pre += cur.latest_number),
                  0
                ),
                ship_amount = (data.ship_records[modelKey] || []).reduce(
                  (pre, cur) => (pre += cur.numbers),
                  0
                );
              return {
                modelKey,
                produce_amount,
                produce_amount_format: decimalAddZero({
                  rawVal: produce_amount,
                  decimalShowRaw: true,
                }),
                ship_amount,
                ship_amount_format: decimalAddZero({
                  rawVal: ship_amount,
                  decimalShowRaw: true,
                }),
                ...val.reduce((pre, cur) => {
                  let date = moment(cur.date).format("MM/DD"),
                    numbers_format =
                      shipData?.[modelKey]?.[date]?.numbers_format ?? null;

                  return {
                    ...pre,
                    [date]: {
                      ...cur,
                      numbers: shipData?.[modelKey]?.[date]?.numbers ?? null,
                      numbers_format,
                      id: shipData?.[modelKey]?.[date]?.id,
                    },
                    [`${date}_format`]: numbers_format,
                  };
                }, {}),
              };
            }
          );
          data.sort(function (pre, next) {
            return pre.modelKey.localeCompare(next.modelKey);
          });
          setTbData(data);
          setRawTbData(data);
          setPreReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }
        dispatch(setProgress(false));
      });
    },
    [preReload, optionData]
  );

  useEffect(() => {
    let temp = [
      {
        key: "modelKey",
        field: "modelKey",
        header: "機種",
        className: "min-w-10rem",
        frozen: true,
        isSort: true,
        sortType: "string",
      },
      {
        key: "produce_amount",
        field: "produce_amount_format",
        header: "生產計劃數量",
        className: "min-w-7rem",
        frozen: true,
        isSort: true,
        sortType: "number",
      },
      {
        key: "ship_amount",
        field: "ship_amount_format",
        header: "已出貨數量",
        className: "min-w-6rem",
        frozen: true,
        isSort: true,
        sortType: "number",
      },
    ];
    if (search?.date) {
      for (
        let i = 0;
        i < Number(moment(search.date).endOf("month").format("DD"));
        i++
      ) {
        let nowDate = moment(search.date)
          .startOf("month")
          .add(i, "days")
          .format("MM/DD");
        temp.push({
          key: `${nowDate}_format`,
          field: `${nowDate}_format`,
          header: nowDate,
          body: (data) => <>{data[nowDate]?.numbers_format ?? "-"}</>,
          className: "min-w-3rem",
          isSort: true,
          sortType: "number",
        });
      }
    }
    setTbColumns(temp);
  }, [pagePath, search]);

  const cellSelect = ({ field, rowIndex }) => {
    if (field.indexOf("_format") !== -1) {
      field = field.replaceAll("_format", "");
    }

    let data = tbData[rowIndex][field];
    if (
      !["modelKey", "produce_amount", "ship_amount"].includes(field) &&
      data
    ) {
      openPop({ type: "ship", data });
    }
  };

  const tbCellClass = (val, { field, rowIndex }) => {
    if (field.indexOf("_format") !== -1) {
      field = field.replaceAll("_format", "");
    }

    let data = tbData[rowIndex][field] || {};
    return `${
      data.status !== undefined ? "cursor-pointer hover:bg-blue-100 " : ""
    }${data.latest_number ? "bg-orange-100" : ""}`;
  };

  return (
    <>
      {renderTable({
        className: "mt-2",
        isLazy: true,
        isPaginator: false,
        options: {
          resizableColumns: true,
          columnResizeMode: "fit",
          cellClassName: tbCellClass,
          cellSelection: true,
          onCellSelect: cellSelect,
        },
      })}

      {renderDialog({
        className: "w-11 lg:w-8 md:w-9 sm:w-10 max-w-35rem",
        children: (
          <>
            {isPop.ship?.isOpen && (
              <ModulePopMachineDailyShipForm
                prePopOption={popOption}
                closePrePop={closePop}
                optionData={optionData}
                setPreReload={setPreReload}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
