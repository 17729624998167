import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import moment from "moment";

import { classNames } from "primereact/utils";

export default function ModuleMachineDailyProduceList({
  search,
  apiData,
  setApiData,
  openPop,
}) {
  const dispatch = useDispatch();
  const [tbColumns, setTbColumns] = useState([]);
  const [sortField, setSortField] = useState([]);

  // useEffect(() => {
  //   const action = ({ startIndex, rows }) => {
  //     let temp = {
  //       ...search,
  //       page: Math.floor(startIndex / rows) + 1,
  //       size: rows,
  //     };
  //     setFilterData(temp);
  //     setSearch(temp);
  //   };
  //   setPageAction(
  //     () =>
  //       ({ startIndex, rows }) =>
  //         action({ startIndex, rows })
  //   );
  // }, [search]);

  useEffect(() => {
    const renderAmount = ({ data, nowDate }) => (
      <div
        className={classNames("p-2", {
          "cursor-pointer hover:bg-blue-100":
            data[nowDate]?.status !== undefined,
          "bg-green-100": data[nowDate]?.status === -1,
          "bg-red-100": data[nowDate]?.status === 1,
        })}
        onClick={() =>
          data[nowDate]
            ? openPop({ type: "detail", data: data[nowDate] })
            : null
        }
      >
        {data[nowDate]?.calculate_amount_format ?? "-"}
      </div>
    );

    let temp = [
      {
        key: "modelKey",
        field: "modelKey",
        header: "機種",
        className: "flex-shrink-0 min-w-10rem text-left",
        style: { left: "0rem" },
        frozen: true,
        isSort: true,
        sortType: "string",
      },
      {
        key: "produce_amount",
        field: "produce_amount_format",
        header: "生產計劃數量",
        className: "flex-shrink-0 min-w-9rem text-left",
        style: { left: "10rem" },
        frozen: true,
        isSort: true,
        sortType: "number",
      },
      {
        key: "ship_amount",
        field: "ship_amount_format",
        header: "已出貨數量",
        className: "flex-shrink-0 min-w-8rem text-left",
        style: { left: "19rem" },
        frozen: true,
        isSort: true,
        sortType: "number",
      },
    ];
    if (search?.date) {
      for (
        let i = 0;
        i < Number(moment(search.date).endOf("month").format("DD"));
        i++
      ) {
        let nowDate = moment(search.date)
          .startOf("month")
          .add(i, "days")
          .format("MM/DD");
        temp.push({
          key: `${nowDate}_format`,
          field: `${nowDate}_format`,
          header: nowDate,
          body: (data) => renderAmount({ data, nowDate }),
          className: "flex-shrink-0 min-w-5rem text-left p-0",
          isSort: true,
          sortType: "number",
        });
      }
    }
    // temp.push({
    //   key: "remain_amount",
    //   field: "remain_amount_format",
    //   header: "剩餘出貨數量",
    //   className: "flex-shrink-0 min-w-8rem text-left",
    //   isSort: true,
    //   sortType: "number",
    //   body: (data) => (
    //     <>{data.remain_amount > 0 ? data.remain_amount_format : "-"}</>
    //   ),
    // });
    setTbColumns(temp);
  }, [search]);

  return (
    <>
      <div className="w-full max-h-50rem overflow-auto">
        <table className="w-full">
          <thead className="sticky top-0 z-2">
            <tr>
              {tbColumns.map((item) => (
                <th
                  key={`head_${item.header}`}
                  className={classNames("bg-gray-100", item.className, {
                    sticky: item.frozen,
                  })}
                  style={item.style}
                >
                  <div
                    className={classNames(
                      "flex align-items-center border-top-1 border-bottom-2 border-300 p-2",
                      {
                        // "border-right-2 border-300":
                        //   item.field === "ship_amount_format",
                      }
                    )}
                  >
                    {item.header}

                    <RenderSort
                      field={item.key}
                      sortType={item.sortType}
                      sortField={sortField}
                      setSortField={setSortField}
                      apiData={apiData}
                      setApiData={setApiData}
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {apiData.filter.map((row) => (
              <tr key={`row_${row.modelKey}`}>
                {tbColumns.map((item) => (
                  <td
                    key={`row_${row.modelKey}_head_${item.header}`}
                    className={classNames(
                      "bg-white border-bottom-1 border-300 p-0",
                      {
                        "sticky z-1": item.frozen,
                      }
                    )}
                    style={item.style}
                  >
                    <div
                      className={classNames("", {
                        "p-2": !item.body,
                        // "border-right-2 border-300":
                        //   item.field === "ship_amount_format",
                      })}
                    >
                      {item.body ? item.body(row) : row[item.field]}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

function RenderSort({
  field,
  sortType,
  sortField,
  setSortField,
  apiData,
  setApiData,
}) {
  const nowIcon = useMemo(
    () => sortField?.find((item) => item.field === field)?.icon ?? null,
    [sortField]
  );

  const sortChange = (icon) => {
    let tempField = [...sortField],
      matchIdx = sortField.findIndex((item) => item.field === field);
    if (icon === null) {
      tempField = [];
    } else {
      tempField = [
        {
          field,
          icon,
          type: sortType,
        },
      ];
    }
    // if (icon === null) {
    //   tempField.splice(matchIdx, 1);
    // } else if (matchIdx === -1) {
    //   tempField.push({
    //     field,
    //     icon,
    //     type: sortType,
    //   });
    // } else {
    //   tempField[matchIdx].icon = icon;
    // }
    setSortField(tempField);

    let tempData = [...apiData.filter];
    tempData.sort(function (pre, next) {
      let flag = 0;
      tempField.forEach((item) => {
        let start = item.icon === 1 ? pre : next,
          end = item.icon === 1 ? next : pre;
        if (item.type === "string") {
          flag = start[item.field].localeCompare(end[item.field]);
        } else if (item.type === "number") {
          let limit = item.icon === 1 ? 99999999 : -99999999;
          flag = (start[item.field] ?? limit) - (end[item.field] ?? limit);
          if (isNaN(flag)) flag = -1;
        }
      });
      return flag;
    });
    if (tempField.length === 0) tempData = apiData.raw;
    setApiData((state) => ({ ...state, filter: tempData }));
  };

  return (
    <>
      <div className="ml-2">
        {nowIcon === null ? (
          <i
            className="pi pi-sort-alt hover:text-blue-200 cursor-pointer"
            onClick={() => sortChange(1)}
          ></i>
        ) : nowIcon === 1 ? (
          <i
            className="pi pi-sort-amount-up-alt text-blue-400 hover:text-blue-200 cursor-pointer"
            onClick={() => sortChange(2)}
          ></i>
        ) : nowIcon === 2 ? (
          <i
            className="pi pi-sort-amount-down text-blue-400 hover:text-blue-200 cursor-pointer"
            onClick={() => sortChange(null)}
          ></i>
        ) : null}
      </div>
    </>
  );
}
