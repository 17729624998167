import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { useAbortedEffect, usePop } from "../../../../components/hooks";
import { MachineAPI } from "../../../../axios/api";
import moment from "moment";

import ModuleMachineDailyProduceList from "./dailyProduceList";
import ModulePopMachineDailyScheduleDetail from "./popDetail";
import { decimalAddZero, setPageSetting } from "../../../../service/common";

import { classNames } from "primereact/utils";

export default function ModuleMachineDailyProduceMain({
  preReload,
  setPreReload,
  search,
  setFilterData,
  setSearch,
  pagePath,
  optionData,
  dateInterval,
}) {
  const dispatch = useDispatch();
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "detail",
      title: () => "明細",
      isOpen: false,
    },
  ]);
  const [apiData, setApiData] = useState({
    raw: [],
    filter: [],
  });

  // 取得列表資料
  useAbortedEffect(
    (signal) => {
      if (!preReload.list || !optionData) return;

      dispatch(setProgress(true));

      setPageSetting({ params: search, page: pagePath });

      let params = {
        ...search,
        date: moment(search.date).startOf("month").format("YYYY-MM-DD"),
        days: Number(moment(search.date).endOf("month").format("DD")),
      };

      MachineAPI.getDailyProduceRecordList({
        data: params,
        options: { signal },
      }).then((result) => {
        console.log(result, params);
        let { Response, data, message } = result;

        if (Response === 1) {
          // 沒有日程資料但有出貨紀錄
          let shipKey = Object.keys(data.ship_records);
          shipKey.forEach((key) => {
            if (!data.daily_produce_record[key])
              data.daily_produce_record[key] = [];
          });

          data = Object.entries(data.daily_produce_record)
            .map(([modelKey, val]) => {
              let produce_amount = val.reduce(
                  (pre, cur) => (pre += cur.latest_number),
                  0
                ),
                ship_amount = (data.ship_records[modelKey] || []).reduce(
                  (pre, cur) => (pre += cur.numbers),
                  0
                ),
                remain_amount = ship_amount;
              return {
                modelKey,
                produce_amount,
                produce_amount_format: decimalAddZero({
                  rawVal: produce_amount,
                  decimalShowRaw: true,
                }),
                ship_amount,
                ship_amount_format: decimalAddZero({
                  rawVal: ship_amount,
                  decimalShowRaw: true,
                }),
                ...val.reduce((pre, cur, cIdx) => {
                  let isRemainShip = remain_amount > 0,
                    isLast = cIdx === val.length - 1,
                    calculate_amount = null;

                  if (isRemainShip) {
                    remain_amount -= cur.latest_number;
                  }

                  if (remain_amount > 0)
                    calculate_amount = isLast
                      ? remain_amount - 2 * remain_amount
                      : 0;
                  else if (!isRemainShip) calculate_amount = cur.latest_number;
                  else calculate_amount = Math.abs(remain_amount);

                  return {
                    ...pre,
                    [moment(cur.date).format("MM/DD")]: {
                      ...cur,
                      calculate_amount_format: decimalAddZero({
                        rawVal: calculate_amount ?? 0,
                        decimalShowRaw: true,
                      }),
                    },
                    [`${moment(cur.date).format("MM/DD")}_format`]:
                      calculate_amount ?? null,
                  };
                }, {}),
                remain_amount,
                remain_amount_format: decimalAddZero({
                  rawVal: remain_amount,
                  decimalShowRaw: true,
                }),
              };
            })
            .filter((item) =>
              optionData.modelList_format.includes(item.modelKey)
            );
          data.sort(function (pre, next) {
            return pre.modelKey.localeCompare(next.modelKey);
          });

          setApiData({
            raw: data,
            filter: data,
          });
          setPreReload((state) => ({
            ...state,
            list: false,
          }));
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }
        dispatch(setProgress(false));
      });
    },
    [preReload, optionData]
  );

  return (
    <>
      <ModuleMachineDailyProduceList
        search={search}
        apiData={apiData}
        setApiData={setApiData}
        openPop={openPop}
      />

      {renderDialog({
        className: "w-11 lg:w-8 md:w-9 sm:w-10 max-w-35rem",
        children: (
          <>
            {isPop.detail?.isOpen && (
              <ModulePopMachineDailyScheduleDetail
                prePopOption={popOption}
                closePrePop={closePop}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
