import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import {
  useAbortedEffect,
  useTable,
  usePop,
} from "../../../../components/hooks";
import { MachineAPI, ShipAPI } from "../../../../axios/api";
import moment from "moment";

import ModulePopMachineDailyScheduleShip from "./popShip";

import { Button } from "primereact/button";

export default function ModulePopMachineDailyScheduleDetail({
  prePopOption,
  closePrePop,
}) {
  const dispatch = useDispatch();
  const { setTbData, setTbColumns, renderTable } = useTable();
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "ship",
      title: () => "出貨",
      isOpen: false,
    },
  ]);
  const [shipReload, setShipReload] = useState(false);
  const [shipData, setShipData] = useState(null);

  useAbortedEffect((signal) => {
    dispatch(setProgress(true));

    let params = {
      date: prePopOption.data.date,
      modelname: prePopOption.data.modelname,
    };

    MachineAPI.getDailyProduceRecordByModelAndDate({
      data: params,
      options: { signal },
    }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        data.forEach((item, index) => {
          let status = null,
            previous = index === 0 ? null : data[index - 1];
          if (previous) {
            item.status =
              previous.numbers > item.numbers
                ? -1
                : previous.numbers < item.numbers
                ? 1
                : null;
          }
        });
        if (data.length > 0) data[data.length - 1].isLast = true;
        setTbData(data);
        setShipReload(true);
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
        dispatch(setProgress(false));
      }
    });
  }, []);

  useAbortedEffect(
    (signal) => {
      if (!shipReload) return;

      dispatch(setProgress(true));

      let params = {
        modelname: prePopOption.data.modelname,
        startDate: moment(prePopOption.data.date).format("YYYY-MM-DD"),
        endDate: moment(prePopOption.data.date).format("YYYY-MM-DD"),
      };

      ShipAPI.getShipRecordsDataList({
        data: params,
        options: { signal },
      }).then((result) => {
        let { Response, data, total, message } = result;

        if (Response === 1) {
          setShipData(total === 0 ? {} : data[0]);
          setShipReload(false);
        } else {
          dispatch(
            setToast({
              severity: "error",
              summary: message,
              detail: "",
            })
          );
        }

        dispatch(setProgress(false));
      });
    },
    [shipReload]
  );

  useEffect(() => {
    let temp = [
      {
        field: "updatedatetime",
        header: "變更時間",
      },
      {
        field: "numbers",
        header: "需求量",
      },
    ];
    setTbColumns(temp);
  }, []);

  const tbRowClass = (data, e) => {
    return {
      "bg-green-100": data.isLast && data.status === -1,
      "bg-red-100": data.isLast && data.status === 1,
    };
  };

  return (
    <>
      <div className="mx-3">
        <div className="flex flex-column sm:flex-row sm:align-items-center text-lg font-bold">
          <div className="mb-2 sm:mb-0">
            <span>機種：</span>
            <span className="text-blue-300">{prePopOption.data.modelname}</span>
          </div>

          {shipData === null ? (
            <></>
          ) : shipData.id ? (
            <div className="flex align-items-center sm:ml-auto">
              <span>出貨數量：</span>
              <span className="text-xl text-orange-400">
                {shipData.numbers}
              </span>
              <Button
                className="p-button-warning p-button-rounded px-1 ml-2"
                type="button"
                icon="pi pi-pencil"
                // label="編輯出貨數量"
                onClick={() =>
                  openPop({
                    type: "ship",
                    data: shipData,
                  })
                }
              />
            </div>
          ) : (
            <Button
              className="p-button-warning p-button-sm w-auto px-3 sm:ml-auto"
              type="button"
              label="新增出貨數量"
              onClick={() =>
                openPop({
                  type: "ship",
                  data: {
                    ...prePopOption.data,
                    id: "add",
                  },
                })
              }
            />
          )}
        </div>

        {renderTable({
          className: "mt-2",
          isLazy: true,
          isPaginator: false,
          options: {
            scrollable: true,
            rowClassName: tbRowClass,
          },
        })}

        <div className="text-right mt-3">
          <Button
            className="p-button-secondary p-button-sm w-auto px-3"
            type="button"
            label="關閉"
            onClick={() => closePrePop({ type: "detail" })}
          />
        </div>
      </div>

      {renderDialog({
        className: "w-11 lg:w-8 md:w-9 sm:w-10 max-w-35rem",
        children: (
          <>
            {isPop.ship?.isOpen && (
              <ModulePopMachineDailyScheduleShip
                prePopOption={popOption}
                closePrePop={closePop}
                setShipReload={setShipReload}
              />
            )}
          </>
        ),
      })}
    </>
  );
}
